
body {
  margin: 0;
}
.logo {
  width: 150px;
  height: 110px;
}

.right-side {
  display: flex;
  align-items: center;
  font-family: "Julius Sans One";
}

.icon {
  width: 24px; /* Increase icon size for better visibility */
  height: 24px; /* Increase icon size for better visibility */
  margin-top: 0.5rem;
  margin-right: 1rem;
}

.navItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.custom-hr {
  border: none;
  height: 2px;
  background-color: #93ceef;
  margin: 0 2rem;
}

.brandName {
  font-size: 1.5rem;
  margin: 0;
  padding-top: 2px;
  margin-left: 0;
}

/* Media queries for responsiveness */

/* For tablets and up */
@media (max-width: 768px) {
  .logo {
    width: 120px;
    height: 90px;
  }

  .brandName {
    font-size: 1.25rem;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .navItems {
    flex-direction: column;
    align-items: center;
  }

  .right-side {
    flex-direction: column;
    align-items: center;
  }

  .custom-hr {
    margin: 1rem 0;
    width: 80%;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .logo {
    width: 100px;
    height: 75px;
  }

  .brandName {
    font-size: 1rem;
    margin-left: 1.7rem;
  }
  .brandSecondName{
    margin-right: 2rem;

  }

  .icon {
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;
  }

  .navItems {
    flex-direction: column;
    align-items: center;
  }

  .right-side {
    flex-direction: column;
    align-items: center;
  }

  .custom-hr {
    margin: 0.5rem 0;
    width: 100%;
  }
}




/* --------------------------------------------------------------------------------- */
.btn-group {
  display: flex;
  justify-content: space-between;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  font-family: "Julius Sans One";
  border: none;
  background-color: white;
}

.nav-btn-left button {
  margin-right: 2rem; /* Adjusted margin for better spacing */
}

button:hover {
  padding-bottom: 8px; 
}

button:active {
  padding-bottom: 8px; 
}

/* Media queries for responsiveness */

/* For tablets and up */
@media screen and (max-width: 768px) {
  .btn-group {
    flex-direction: row;
    align-items: center;
  }

  .nav-btn-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav-btn-left button {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 1rem; /* Add bottom margin */
  }

  button {
    font-size: 14px; /* Adjust font size for tablets */
  }
}

/* For mobile devices */
@media screen and (max-width: 480px) {
  .btn-group {
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .nav-btn-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav-btn-left button {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 0; /* Add bottom margin */
  }

  button {
    font-size: 10px; /* Adjust font size for mobile devices */
    padding: 8px 10px; /* Adjust padding for mobile devices */
  }

  .nav-btn-left button:last-child {
    margin-bottom: 0; /* Remove bottom margin for last button */
  }
}

/* ------------------------------------------------- */
.card {
  position: relative;
  
  width: 100%; /* Make the card take full width of its container */
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0; /* Reset margin */
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* CSS for mobile devices */
@media only screen and (max-width: 767px) {
  .card-title {
    font-size: 20px; /* Decrease font size for smaller screens */
    bottom: 10px; /* Adjust position */
    left: 10px; /* Adjust position */
  }
}

/* CSS for tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .card-title {
    font-size: 22px; /* Adjust font size for tablets */
  }
}

/* CSS for larger screens (desktops, laptops) */
@media only screen and (min-width: 1024px) {
  .card-title {
    font-size: 24px; /* Original font size for larger screens */
    bottom: 20px; /* Reset position */
    left: 20px; /* Reset position */
  }
}

/* ----------------------------------------------------- */