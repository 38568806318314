.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
  }
  
  .container {
  
    margin: 0 auto;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-text {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .subscription-form {
    display: flex;
    align-items: center;
  }
  
  .email-input {
    padding: 10px;
    border: none;
    border-radius: 5px 0 0 5px;
  }
  
  .subscribe-button {
    background-color: #ff4500;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  
  .subscribe-button:hover {
    background-color: #ff6a36;
  }

 
  