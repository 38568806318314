/* .quoted-paragraph-container {
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  .quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    
    width: 70%;
  }
  
  .quote-icon {
    font-size: 80px;
    margin-right: 60rem;
    
  }
  
  .quote-text {
    font-size: 18px;
    text-align: center;
    font-family: Arial, sans-serif;
  }

  
  .inverted {
    transform: scaleX(-1);
    margin-left: 120rem;
  }
   */

   .quoted-paragraph-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 800px; /* Maximum width for larger screens */
    background-color: #f9f9f9; /* Add background color for better visibility */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better design */
  }
  
  .quote-icon {
    font-size: 40px;
    margin-bottom: 20px;
  }
  
  .quote-text {
    font-size: 18px;
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 10px 0;
  }
  
  .inverted {
    transform: scaleX(-1);
    margin-top: 20px;
  }
  
  /* Responsive styles for mobile devices */
  @media (max-width: 767px) {
    .quote-icon {
      font-size: 30px;
    }
  
    .quote-text {
      font-size: 16px;
    }
  
    .inverted {
      margin-top: 10px;
    }
  }
  
  /* Responsive styles for tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    .quote-icon {
      font-size: 35px;
    }
  
    .quote-text {
      font-size: 17px;
    }
  }
  
  /* Responsive styles for larger screens */
  @media (min-width: 1024px) {
    .quote-icon {
      font-size: 40px;
    }
  
    .quote-text {
      font-size: 18px;
    }
  }
  