/* .about-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: rgba(14, 127, 190, 1);
    color: #fff;
    line-height: 1.6;
    width: 90%;
    margin-left: 2rem;
    border-radius: 0px 100px 0px 100px;
  }
  
  .hr-container {
    margin-bottom: 20px;
  }
  
  .custom-hr {
    border: 1px solid #ddd;
  }
  
  .content-container {
    margin-top: 20px;
  }
  
  .title {
    font-size: 28px;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .description {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  @media (min-width: 768px) {
    .about-container {
      padding: 40px;
    }
  
    .title {
      font-size: 32px;
    }
  
    .description {
      font-size: 18px;
    }
  }
  /* ------------------------------- */
  /* .solutions {
    font-size: 2em;
    color: #1e8cf2;
    text-shadow: 2px 2px 4px rgba(30, 140, 242, 0.5);
    -webkit-text-stroke: 1px #1e8cf2;
    position: relative;
    margin-left: 16rem;
}

.solutions::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-shadow: 2px 2px 4px rgba(30, 140, 242, 0.5);
    pointer-events: stroke;
}
 */ 
 /* ------------------------------------ */

 .about-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: rgba(14, 127, 190, 1);
  color: #fff;
  line-height: 1.6;
  width: 90%;
  margin: 0 auto;
  border-radius: 0px 100px 0px 100px;
}

.hr-container {
  margin-bottom: 20px;
}

.custom-hr {
  border: 1px solid #ddd;
}

.content-container {
  margin-top: 20px;
}

.title {
  font-size: 28px;
  color: #fff;
  margin-bottom: 10px;
}

.description {
  margin-bottom: 20px;
  text-align: justify;
}

/* CSS for larger screens */
@media (min-width: 768px) {
  .about-container {
    padding: 40px;
  }

  .title {
    font-size: 32px;
  }

  .description {
    font-size: 18px;
  }
}

/* CSS for mobile devices */
@media (max-width: 767px) {
  .about-container {
    width: 90%;
    padding: 10px;
    border-radius: 0px;
  }

  .title {
    font-size: 24px;
  }

  .description {
    font-size: 16px;
  }
}

.solutions {
  font-size: 2em;
  color: #1e8cf2;
  text-shadow: 2px 2px 4px rgba(30, 140, 242, 0.5);
  -webkit-text-stroke: 1px #1e8cf2;
  position: relative;
  text-align: center; /* Center the text */
  margin: 2rem 0; /* Add some vertical margin */
}

.solutions::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-shadow: 2px 2px 4px rgba(30, 140, 242, 0.5);
  pointer-events: stroke;
}
