.social-icons {
    display: flex;
    justify-content: center;
    margin-top: 0; /* Add this line to remove space above */
    padding-top: 0; /* Alternatively, if padding is causing the space, add this line */
  }
  
  .icon {
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin: 0; /* Add this line to reset margin */
    padding-right: 30px;
    padding-top: 6px; /* Add this line to reset padding */
    
  }
  
  